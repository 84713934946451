import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Hero from "../components/ui/Hero"
import "../assets/style/sports.scss"
import Tag from "../components/ui/Tag"
import { StructuredText } from "react-datocms"
import Gallery from "../components/ui/Gallery"
export default function News({ pageContext }) {
  useEffect(() => {
    console.log(pageContext)
  }, [])
  const getContent = item => {
    if (item?.children) {
      return item?.children?.map(child => {
        return getContent(child?.children)
      })
    } else {
      switch (item?.type) {
        case "paragraph":
          return <p className="text-paragraph">{item?.value}</p>
      }
    }
  }
  const scrollTo = content => {
    // const element = document.getElementById("content");
    // element.scrollIntoView();
    const allHeadings3 = document.querySelectorAll("h3")
    ;[...allHeadings3].map(heading => {
      if (heading.innerText === content) {
        heading.scrollIntoView()
      }
    })
  }
  return (
    <Layout>
      <Hero
        title={pageContext?.title}
        subTitle={pageContext?.subtitle}
        image={pageContext?.media}
        label={{
          message: pageContext.tagMessage,
          color: pageContext.tagColor,
        }}
      />
      <div className="main-container row">
        <div className="col-xs-12 col-md-3">
          <div className="sports-contents">
            <p className="sports-contents-headline heading-6">Inhalt</p>
            <ul className="sports-contents-list">
              {pageContext?.content?.value?.document?.children?.map(item => {
                if (item?.type === "heading" && item?.level === 3) {
                  return (
                    <li onClick={() => scrollTo(item?.children[0]?.value)}>
                      {item?.children[0]?.value}
                    </li>
                  )
                }
              })}
              {/* <li>
                <a href="#">Trainingszeiten</a>
              </li>
              <li>
                <a href="#">Übungsleitung</a>
              </li>
              <li>
                <a href="#">Traningsort</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-md-9">
          {pageContext?.tagMessage && (
            <Tag color={pageContext?.tagColor}>{pageContext?.tagMessage}</Tag>
          )}
          <div className="sports-content text-paragraph">
            <StructuredText
              data={pageContext?.content}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsGallery":
                    return <Gallery media={record?.media}/>
                  default:
                    return null
                }
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
